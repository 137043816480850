import Retool from 'react-retool';
import React from 'react';

function App() {
  return (
    <Retool 
      url="https://hopsndrops.retool.com/embedded/public/aed4463a-b9ee-409f-b807-81bb0c332034"
    />
  );
}

export default App;